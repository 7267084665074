import React from 'react';
import owner from "../media/ownerpic.jpg";
import manager from "../media/managerpic.jpg";
import logo from "../media/logoOnly.png";
import arrowRight from "../media/arrowRight.png";
import arrowLeft from "../media/arrowLeft.png";
import '../styles/Team.css'
function Section7() {
    const nextTeamMember = () =>{
        let lists = document.querySelectorAll('.slide');
        document.getElementById('features-track').appendChild(lists[0]); 
    }
    return (
        <>
            <div className='section8'>
                <div className='team-content'>    
                    <div className='sec8Content'>
                        <p className='premiumhead'>About G1 Real Estate</p>
                        <h4 className='sec9headmain'>Your Trusted Partner in Crafting Your Real Estate Success Story.</h4>
                        <p className='sec8Fullcontent'>
                            Welcome to G1 Real Estate, your gateway to luxury living in Dubai. Our mission is simple: to redefine opulence and sophistication in the real estate market, offering unparalleled service and expertise to every client.
                            <br />
                            <br />
                            Our vision is to be the premier destination for luxury property in Dubai, known for our commitment to excellence and our dedication to exceeding client expectations.
                            <br />
                                                    <br />
                            At G1 Real Estate, our values guide everything we do. We believe in honesty, integrity, and transparency, and we strive to treat every client with the utmost respect and professionalism.
                            <br />
                            <br />
                            Whether you're looking to buy, sell, or invest in property, we're here to help you every step of the way. Trust G1 Real Estate to make your real estate dreams a reality.
                            <br/>

                        </p>
                        <button className='contactNav sec8team'>Meet the Team</button>
                        <button className='contactNav sec8findout'>Find out More</button>
                    </div>
                    
                    <div className='sec8rightSide'>
                        <div id="features-track" className="carr-container">      
                            <div className="slide" data-index="1" style={{
                                    background: `url(${owner} )`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover',
                                }}>
                                <div className="about-feature">
                                    <div className='details'>
                                        <div className="description-container">
                                            <h3>Hamoodash Alameri</h3>
                                            <p>CEO & Licensed Real Estate Broker</p>
                                        </div>
                                        <div className='languages'>
                                            <p>Languages: <span>English, Arabic</span></p>
                                        </div>
                                    </div>
                                    <div className='contact-info'>
                                        <ul className='contact-networks'>
                                            <li>
                                                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M24.3983 16.7168L17.4882 15.9169L14.1839 19.2212C10.4616 17.3281 7.43604 14.3026 5.54292 10.5802L8.86032 7.26284L8.06048 0.378906H0.835628C0.0751177 13.7272 11.0501 24.7021 24.3983 23.9416V16.7168Z" fill="white"/>
                                                </svg>
                                            </li>
                                            <li>
                                                <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M21.2536 3.65234C18.9155 1.30859 15.8016 0.0195312 12.4925 0.0195312C5.66211 0.0195312 0.104074 5.57757 0.104074 12.4079C0.104074 14.5898 0.67327 16.7215 1.75586 18.6021L-0.00195312 25.0195L6.56613 23.2952C8.37416 24.2829 10.411 24.8019 12.4869 24.8019H12.4925C19.3172 24.8019 24.998 19.2439 24.998 12.4135C24.998 9.10435 23.5918 5.99609 21.2536 3.65234ZM12.4925 22.7148C10.6398 22.7148 8.82617 22.2182 7.24693 21.2807L6.87305 21.0575L2.97796 22.0787L4.0159 18.2785L3.77037 17.8878C2.738 16.2472 2.19671 14.3555 2.19671 12.4079C2.19671 6.7327 6.81724 2.11217 12.498 2.11217C15.2492 2.11217 17.8329 3.18359 19.7748 5.13114C21.7168 7.07868 22.911 9.66239 22.9054 12.4135C22.9054 18.0943 18.1677 22.7148 12.4925 22.7148ZM18.1398 15.0028C17.8329 14.8465 16.3094 14.0988 16.0248 13.9983C15.7402 13.8923 15.5338 13.8421 15.3273 14.1546C15.1208 14.4671 14.5293 15.159 14.3451 15.3711C14.1666 15.5776 13.9824 15.6055 13.6755 15.4492C11.8563 14.5396 10.6621 13.8253 9.46233 11.7662C9.14425 11.2193 9.78041 11.2584 10.3719 10.0753C10.4724 9.86886 10.4222 9.69029 10.344 9.53404C10.2659 9.37779 9.64648 7.85435 9.38979 7.23493C9.13867 6.63225 8.88198 6.71596 8.69224 6.7048C8.51367 6.69364 8.3072 6.69364 8.10073 6.69364C7.89425 6.69364 7.55943 6.77176 7.27483 7.07868C6.99023 7.39118 6.19224 8.13895 6.19224 9.66239C6.19224 11.1858 7.30273 12.659 7.4534 12.8655C7.60965 13.072 9.63532 16.197 12.7436 17.5419C14.7079 18.3901 15.478 18.4626 16.4601 18.3175C17.0572 18.2282 18.2905 17.5698 18.5472 16.8443C18.8038 16.1189 18.8039 15.4994 18.7257 15.3711C18.6532 15.2316 18.4467 15.1535 18.1398 15.0028Z" fill="white"/>
                                                </svg>
                                            </li>
                                            <li>
                                                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M13.4556 10.728L14.4081 10.9675C15.4145 9.09219 18.0401 8.01252 20.4579 8.46738C21.6463 8.69095 22.7557 9.28316 23.5707 10.3123C24.3857 11.3413 24.9369 12.8461 24.9369 14.9465V25.4732H21.0983V16.7313C21.0983 15.2493 20.6512 14.1031 19.9257 13.3279C19.2013 12.5538 18.2315 12.1858 17.2646 12.2094C15.3059 12.2573 13.4556 13.8877 13.4556 16.7313V25.4732H9.61701V8.47611H13.4556V10.728ZM5.20847 8.47611V25.4732H1.36988V8.47611H5.20847ZM0.88475 2.94003C0.88475 1.59557 1.95332 0.525863 3.28917 0.525863C4.62502 0.525863 5.69359 1.59557 5.69359 2.94003C5.69359 4.28449 4.62502 5.3542 3.28917 5.3542C1.95332 5.3542 0.88475 4.28449 0.88475 2.94003Z" fill="white" stroke="white" stroke-width="1.01266"/>
                                                </svg>
                                            </li>
                                        </ul>
                                    </div>                          
                                    <button className="next-slide-btn" onclick={nextTeamMember}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M21 12H3M21 12L15 6M21 12L15 18" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            <div className="slide" data-index="1" style={{
                                    background: `url(${owner} )`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover',
                                }}>
                                <div className="about-feature">
                                    <div className='details'>
                                        <div className="description-container">
                                            <h3>Hamoodash Alameri</h3>
                                            <p>CEO & Licensed Real Estate Broker</p>
                                        </div>
                                        <div className='languages'>
                                            <p>Languages: <span>English, Arabic</span></p>
                                        </div>
                                    </div>
                                    <div className='contact-info'>
                                        <ul className='contact-networks'>
                                            <li>
                                                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M24.3983 16.7168L17.4882 15.9169L14.1839 19.2212C10.4616 17.3281 7.43604 14.3026 5.54292 10.5802L8.86032 7.26284L8.06048 0.378906H0.835628C0.0751177 13.7272 11.0501 24.7021 24.3983 23.9416V16.7168Z" fill="white"/>
                                                </svg>
                                            </li>
                                            <li>
                                                <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M21.2536 3.65234C18.9155 1.30859 15.8016 0.0195312 12.4925 0.0195312C5.66211 0.0195312 0.104074 5.57757 0.104074 12.4079C0.104074 14.5898 0.67327 16.7215 1.75586 18.6021L-0.00195312 25.0195L6.56613 23.2952C8.37416 24.2829 10.411 24.8019 12.4869 24.8019H12.4925C19.3172 24.8019 24.998 19.2439 24.998 12.4135C24.998 9.10435 23.5918 5.99609 21.2536 3.65234ZM12.4925 22.7148C10.6398 22.7148 8.82617 22.2182 7.24693 21.2807L6.87305 21.0575L2.97796 22.0787L4.0159 18.2785L3.77037 17.8878C2.738 16.2472 2.19671 14.3555 2.19671 12.4079C2.19671 6.7327 6.81724 2.11217 12.498 2.11217C15.2492 2.11217 17.8329 3.18359 19.7748 5.13114C21.7168 7.07868 22.911 9.66239 22.9054 12.4135C22.9054 18.0943 18.1677 22.7148 12.4925 22.7148ZM18.1398 15.0028C17.8329 14.8465 16.3094 14.0988 16.0248 13.9983C15.7402 13.8923 15.5338 13.8421 15.3273 14.1546C15.1208 14.4671 14.5293 15.159 14.3451 15.3711C14.1666 15.5776 13.9824 15.6055 13.6755 15.4492C11.8563 14.5396 10.6621 13.8253 9.46233 11.7662C9.14425 11.2193 9.78041 11.2584 10.3719 10.0753C10.4724 9.86886 10.4222 9.69029 10.344 9.53404C10.2659 9.37779 9.64648 7.85435 9.38979 7.23493C9.13867 6.63225 8.88198 6.71596 8.69224 6.7048C8.51367 6.69364 8.3072 6.69364 8.10073 6.69364C7.89425 6.69364 7.55943 6.77176 7.27483 7.07868C6.99023 7.39118 6.19224 8.13895 6.19224 9.66239C6.19224 11.1858 7.30273 12.659 7.4534 12.8655C7.60965 13.072 9.63532 16.197 12.7436 17.5419C14.7079 18.3901 15.478 18.4626 16.4601 18.3175C17.0572 18.2282 18.2905 17.5698 18.5472 16.8443C18.8038 16.1189 18.8039 15.4994 18.7257 15.3711C18.6532 15.2316 18.4467 15.1535 18.1398 15.0028Z" fill="white"/>
                                                </svg>
                                            </li>
                                            <li>
                                                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M13.4556 10.728L14.4081 10.9675C15.4145 9.09219 18.0401 8.01252 20.4579 8.46738C21.6463 8.69095 22.7557 9.28316 23.5707 10.3123C24.3857 11.3413 24.9369 12.8461 24.9369 14.9465V25.4732H21.0983V16.7313C21.0983 15.2493 20.6512 14.1031 19.9257 13.3279C19.2013 12.5538 18.2315 12.1858 17.2646 12.2094C15.3059 12.2573 13.4556 13.8877 13.4556 16.7313V25.4732H9.61701V8.47611H13.4556V10.728ZM5.20847 8.47611V25.4732H1.36988V8.47611H5.20847ZM0.88475 2.94003C0.88475 1.59557 1.95332 0.525863 3.28917 0.525863C4.62502 0.525863 5.69359 1.59557 5.69359 2.94003C5.69359 4.28449 4.62502 5.3542 3.28917 5.3542C1.95332 5.3542 0.88475 4.28449 0.88475 2.94003Z" fill="white" stroke="white" stroke-width="1.01266"/>
                                                </svg>
                                            </li>
                                        </ul>
                                    </div>                          
                                    <button className="next-slide-btn" onclick={nextTeamMember}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M21 12H3M21 12L15 6M21 12L15 18" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            <div className="slide" data-index="1" style={{
                                    background: `url(${owner} )`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover',
                                }}>
                                <div className="about-feature">
                                    <div className='details'>
                                        <div className="description-container">
                                            <h3>Hamoodash Alameri</h3>
                                            <p>CEO & Licensed Real Estate Broker</p>
                                        </div>
                                        <div className='languages'>
                                            <p>Languages: <span>English, Arabic</span></p>
                                        </div>
                                    </div>
                                    <div className='contact-info'>
                                        <ul className='contact-networks'>
                                            <li>
                                                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M24.3983 16.7168L17.4882 15.9169L14.1839 19.2212C10.4616 17.3281 7.43604 14.3026 5.54292 10.5802L8.86032 7.26284L8.06048 0.378906H0.835628C0.0751177 13.7272 11.0501 24.7021 24.3983 23.9416V16.7168Z" fill="white"/>
                                                </svg>
                                            </li>
                                            <li>
                                                <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M21.2536 3.65234C18.9155 1.30859 15.8016 0.0195312 12.4925 0.0195312C5.66211 0.0195312 0.104074 5.57757 0.104074 12.4079C0.104074 14.5898 0.67327 16.7215 1.75586 18.6021L-0.00195312 25.0195L6.56613 23.2952C8.37416 24.2829 10.411 24.8019 12.4869 24.8019H12.4925C19.3172 24.8019 24.998 19.2439 24.998 12.4135C24.998 9.10435 23.5918 5.99609 21.2536 3.65234ZM12.4925 22.7148C10.6398 22.7148 8.82617 22.2182 7.24693 21.2807L6.87305 21.0575L2.97796 22.0787L4.0159 18.2785L3.77037 17.8878C2.738 16.2472 2.19671 14.3555 2.19671 12.4079C2.19671 6.7327 6.81724 2.11217 12.498 2.11217C15.2492 2.11217 17.8329 3.18359 19.7748 5.13114C21.7168 7.07868 22.911 9.66239 22.9054 12.4135C22.9054 18.0943 18.1677 22.7148 12.4925 22.7148ZM18.1398 15.0028C17.8329 14.8465 16.3094 14.0988 16.0248 13.9983C15.7402 13.8923 15.5338 13.8421 15.3273 14.1546C15.1208 14.4671 14.5293 15.159 14.3451 15.3711C14.1666 15.5776 13.9824 15.6055 13.6755 15.4492C11.8563 14.5396 10.6621 13.8253 9.46233 11.7662C9.14425 11.2193 9.78041 11.2584 10.3719 10.0753C10.4724 9.86886 10.4222 9.69029 10.344 9.53404C10.2659 9.37779 9.64648 7.85435 9.38979 7.23493C9.13867 6.63225 8.88198 6.71596 8.69224 6.7048C8.51367 6.69364 8.3072 6.69364 8.10073 6.69364C7.89425 6.69364 7.55943 6.77176 7.27483 7.07868C6.99023 7.39118 6.19224 8.13895 6.19224 9.66239C6.19224 11.1858 7.30273 12.659 7.4534 12.8655C7.60965 13.072 9.63532 16.197 12.7436 17.5419C14.7079 18.3901 15.478 18.4626 16.4601 18.3175C17.0572 18.2282 18.2905 17.5698 18.5472 16.8443C18.8038 16.1189 18.8039 15.4994 18.7257 15.3711C18.6532 15.2316 18.4467 15.1535 18.1398 15.0028Z" fill="white"/>
                                                </svg>
                                            </li>
                                            <li>
                                                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M13.4556 10.728L14.4081 10.9675C15.4145 9.09219 18.0401 8.01252 20.4579 8.46738C21.6463 8.69095 22.7557 9.28316 23.5707 10.3123C24.3857 11.3413 24.9369 12.8461 24.9369 14.9465V25.4732H21.0983V16.7313C21.0983 15.2493 20.6512 14.1031 19.9257 13.3279C19.2013 12.5538 18.2315 12.1858 17.2646 12.2094C15.3059 12.2573 13.4556 13.8877 13.4556 16.7313V25.4732H9.61701V8.47611H13.4556V10.728ZM5.20847 8.47611V25.4732H1.36988V8.47611H5.20847ZM0.88475 2.94003C0.88475 1.59557 1.95332 0.525863 3.28917 0.525863C4.62502 0.525863 5.69359 1.59557 5.69359 2.94003C5.69359 4.28449 4.62502 5.3542 3.28917 5.3542C1.95332 5.3542 0.88475 4.28449 0.88475 2.94003Z" fill="white" stroke="white" stroke-width="1.01266"/>
                                                </svg>
                                            </li>
                                        </ul>
                                    </div>                          
                                    <button className="next-slide-btn" onclick="nextSlide()">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M21 12H3M21 12L15 6M21 12L15 18" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            <div className="slide" data-index="1" style={{
                                    background: `url(${owner} )`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover',
                                }}>
                                <div className="about-feature">
                                    <div className='details'>
                                        <div className="description-container">
                                            <h3>Hamoodash Alameri</h3>
                                            <p>CEO & Licensed Real Estate Broker</p>
                                        </div>
                                        <div className='languages'>
                                            <p>Languages: <span>English, Arabic</span></p>
                                        </div>
                                    </div>
                                    <div className='contact-info'>
                                        <ul className='contact-networks'>
                                            <li>
                                                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M24.3983 16.7168L17.4882 15.9169L14.1839 19.2212C10.4616 17.3281 7.43604 14.3026 5.54292 10.5802L8.86032 7.26284L8.06048 0.378906H0.835628C0.0751177 13.7272 11.0501 24.7021 24.3983 23.9416V16.7168Z" fill="white"/>
                                                </svg>
                                            </li>
                                            <li>
                                                <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M21.2536 3.65234C18.9155 1.30859 15.8016 0.0195312 12.4925 0.0195312C5.66211 0.0195312 0.104074 5.57757 0.104074 12.4079C0.104074 14.5898 0.67327 16.7215 1.75586 18.6021L-0.00195312 25.0195L6.56613 23.2952C8.37416 24.2829 10.411 24.8019 12.4869 24.8019H12.4925C19.3172 24.8019 24.998 19.2439 24.998 12.4135C24.998 9.10435 23.5918 5.99609 21.2536 3.65234ZM12.4925 22.7148C10.6398 22.7148 8.82617 22.2182 7.24693 21.2807L6.87305 21.0575L2.97796 22.0787L4.0159 18.2785L3.77037 17.8878C2.738 16.2472 2.19671 14.3555 2.19671 12.4079C2.19671 6.7327 6.81724 2.11217 12.498 2.11217C15.2492 2.11217 17.8329 3.18359 19.7748 5.13114C21.7168 7.07868 22.911 9.66239 22.9054 12.4135C22.9054 18.0943 18.1677 22.7148 12.4925 22.7148ZM18.1398 15.0028C17.8329 14.8465 16.3094 14.0988 16.0248 13.9983C15.7402 13.8923 15.5338 13.8421 15.3273 14.1546C15.1208 14.4671 14.5293 15.159 14.3451 15.3711C14.1666 15.5776 13.9824 15.6055 13.6755 15.4492C11.8563 14.5396 10.6621 13.8253 9.46233 11.7662C9.14425 11.2193 9.78041 11.2584 10.3719 10.0753C10.4724 9.86886 10.4222 9.69029 10.344 9.53404C10.2659 9.37779 9.64648 7.85435 9.38979 7.23493C9.13867 6.63225 8.88198 6.71596 8.69224 6.7048C8.51367 6.69364 8.3072 6.69364 8.10073 6.69364C7.89425 6.69364 7.55943 6.77176 7.27483 7.07868C6.99023 7.39118 6.19224 8.13895 6.19224 9.66239C6.19224 11.1858 7.30273 12.659 7.4534 12.8655C7.60965 13.072 9.63532 16.197 12.7436 17.5419C14.7079 18.3901 15.478 18.4626 16.4601 18.3175C17.0572 18.2282 18.2905 17.5698 18.5472 16.8443C18.8038 16.1189 18.8039 15.4994 18.7257 15.3711C18.6532 15.2316 18.4467 15.1535 18.1398 15.0028Z" fill="white"/>
                                                </svg>
                                            </li>
                                            <li>
                                                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M13.4556 10.728L14.4081 10.9675C15.4145 9.09219 18.0401 8.01252 20.4579 8.46738C21.6463 8.69095 22.7557 9.28316 23.5707 10.3123C24.3857 11.3413 24.9369 12.8461 24.9369 14.9465V25.4732H21.0983V16.7313C21.0983 15.2493 20.6512 14.1031 19.9257 13.3279C19.2013 12.5538 18.2315 12.1858 17.2646 12.2094C15.3059 12.2573 13.4556 13.8877 13.4556 16.7313V25.4732H9.61701V8.47611H13.4556V10.728ZM5.20847 8.47611V25.4732H1.36988V8.47611H5.20847ZM0.88475 2.94003C0.88475 1.59557 1.95332 0.525863 3.28917 0.525863C4.62502 0.525863 5.69359 1.59557 5.69359 2.94003C5.69359 4.28449 4.62502 5.3542 3.28917 5.3542C1.95332 5.3542 0.88475 4.28449 0.88475 2.94003Z" fill="white" stroke="white" stroke-width="1.01266"/>
                                                </svg>
                                            </li>
                                        </ul>
                                    </div>                          
                                    <button className="next-slide-btn" onclick="nextSlide()">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M21 12H3M21 12L15 6M21 12L15 18" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Section7;