
const socials = [
    {
        "name": "LinkedIn",
        "url": "https://www.linkedin.com/company/g1-real-estate",
        "svg": `<svg width='18' height='19' viewBox='0 0 18 19' fill='none' xmlns='http://www.w3.org/2000/svg'><g opacity='0.8'><path d='M9.14195 8.08148L10.2056 8.34965C10.8567 7.1404 12.5914 6.41021 14.2132 6.7143C15.001 6.86202 15.7297 7.25146 16.2644 7.92432C16.7989 8.59696 17.1738 9.59649 17.1738 11.0227V18.1496H14.9113V12.2671C14.9113 11.1902 14.5845 10.3353 14.0333 9.74824C13.4834 9.16255 12.7463 8.88392 12.0128 8.90181C10.5185 8.93826 9.14195 10.1788 9.14195 12.2671V18.1496H6.87945V6.72398H9.14195V8.08148ZM3.37258 6.72398V18.1496H1.11008V6.72398H3.37258ZM0.770703 2.65148C0.770703 1.83262 1.42246 1.18086 2.24133 1.18086C3.06019 1.18086 3.71195 1.83262 3.71195 2.65148C3.71195 3.47035 3.06019 4.12211 2.24133 4.12211C1.42246 4.12211 0.770703 3.47035 0.770703 2.65148Z' fill='white' stroke='white' stroke-width='1.13125'/></g></svg>`
    },
    {
        "name": "LinkedIn",
        "url": "https://www.linkedin.com/company/g1-real-estate",
        "svg": `<svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.8">
        <path d="M9.14195 8.08148L10.2056 8.34965C10.8567 7.1404 12.5914 6.41021 14.2132 6.7143C15.001 6.86202 15.7297 7.25146 16.2644 7.92432C16.7989 8.59696 17.1738 9.59649 17.1738 11.0227V18.1496H14.9113V12.2671C14.9113 11.1902 14.5845 10.3353 14.0333 9.74824C13.4834 9.16255 12.7463 8.88392 12.0128 8.90181C10.5185 8.93826 9.14195 10.1788 9.14195 12.2671V18.1496H6.87945V6.72398H9.14195V8.08148ZM3.37258 6.72398V18.1496H1.11008V6.72398H3.37258ZM0.770703 2.65148C0.770703 1.83262 1.42246 1.18086 2.24133 1.18086C3.06019 1.18086 3.71195 1.83262 3.71195 2.65148C3.71195 3.47035 3.06019 4.12211 2.24133 4.12211C1.42246 4.12211 0.770703 3.47035 0.770703 2.65148Z" fill="white" stroke="white" stroke-width="1.13125"/>
        </g>
        </svg>
        `
    },
    {
        "name": "LinkedIn",
        "url": "https://www.linkedin.com/company/g1-real-estate",
        "svg": `<svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.8">
        <path d="M9.14195 8.08148L10.2056 8.34965C10.8567 7.1404 12.5914 6.41021 14.2132 6.7143C15.001 6.86202 15.7297 7.25146 16.2644 7.92432C16.7989 8.59696 17.1738 9.59649 17.1738 11.0227V18.1496H14.9113V12.2671C14.9113 11.1902 14.5845 10.3353 14.0333 9.74824C13.4834 9.16255 12.7463 8.88392 12.0128 8.90181C10.5185 8.93826 9.14195 10.1788 9.14195 12.2671V18.1496H6.87945V6.72398H9.14195V8.08148ZM3.37258 6.72398V18.1496H1.11008V6.72398H3.37258ZM0.770703 2.65148C0.770703 1.83262 1.42246 1.18086 2.24133 1.18086C3.06019 1.18086 3.71195 1.83262 3.71195 2.65148C3.71195 3.47035 3.06019 4.12211 2.24133 4.12211C1.42246 4.12211 0.770703 3.47035 0.770703 2.65148Z" fill="white" stroke="white" stroke-width="1.13125"/>
        </g>
        </svg>
        `
    },
    {
        "name": "LinkedIn",
        "url": "https://www.linkedin.com/company/g1-real-estate",
        "svg": `<svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.8">
        <path d="M9.14195 8.08148L10.2056 8.34965C10.8567 7.1404 12.5914 6.41021 14.2132 6.7143C15.001 6.86202 15.7297 7.25146 16.2644 7.92432C16.7989 8.59696 17.1738 9.59649 17.1738 11.0227V18.1496H14.9113V12.2671C14.9113 11.1902 14.5845 10.3353 14.0333 9.74824C13.4834 9.16255 12.7463 8.88392 12.0128 8.90181C10.5185 8.93826 9.14195 10.1788 9.14195 12.2671V18.1496H6.87945V6.72398H9.14195V8.08148ZM3.37258 6.72398V18.1496H1.11008V6.72398H3.37258ZM0.770703 2.65148C0.770703 1.83262 1.42246 1.18086 2.24133 1.18086C3.06019 1.18086 3.71195 1.83262 3.71195 2.65148C3.71195 3.47035 3.06019 4.12211 2.24133 4.12211C1.42246 4.12211 0.770703 3.47035 0.770703 2.65148Z" fill="white" stroke="white" stroke-width="1.13125"/>
        </g>
        </svg>
        `
    },
    {
        "name": "LinkedIn",
        "url": "https://www.linkedin.com/company/g1-real-estate",
        "svg": `<svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.8">
        <path d="M9.14195 8.08148L10.2056 8.34965C10.8567 7.1404 12.5914 6.41021 14.2132 6.7143C15.001 6.86202 15.7297 7.25146 16.2644 7.92432C16.7989 8.59696 17.1738 9.59649 17.1738 11.0227V18.1496H14.9113V12.2671C14.9113 11.1902 14.5845 10.3353 14.0333 9.74824C13.4834 9.16255 12.7463 8.88392 12.0128 8.90181C10.5185 8.93826 9.14195 10.1788 9.14195 12.2671V18.1496H6.87945V6.72398H9.14195V8.08148ZM3.37258 6.72398V18.1496H1.11008V6.72398H3.37258ZM0.770703 2.65148C0.770703 1.83262 1.42246 1.18086 2.24133 1.18086C3.06019 1.18086 3.71195 1.83262 3.71195 2.65148C3.71195 3.47035 3.06019 4.12211 2.24133 4.12211C1.42246 4.12211 0.770703 3.47035 0.770703 2.65148Z" fill="white" stroke="white" stroke-width="1.13125"/>
        </g>
        </svg>
        `
    }
]

export default socials