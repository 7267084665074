import React from 'react';
import bg1 from "../media/sec9bg1.jpg";
import man from "../media/sec9man.jpg";
import '../styles/Testimonial.css'


function Section8() {

    const nextTestimonial = () =>{
        let lists = document.querySelectorAll('.testimonial-slide');
        document.querySelector('.containersec9').appendChild(lists[0]);
    }

    
    return (
        <>
            <div className='section9'>
                <div className='sec9content'>
                    <p className='sec9before'>Our Testimonials</p>
                    <h4 className='sec9headmain'>Hear It From Them</h4>
                </div>
                <div className='sec9main'>
                <div class="containersec9">
                        <div className='testimonial-slide'>
                            <div class="contentsec9">
                                <div className='testimonial-image-container'>
                                    <div className='agujero'>
                                        <img src={man} className='manroundsec9' />
                                    </div>
                                </div>
                                <div className='testimonial-content'>
                                    <div className='testimonial'>
                                        <p className='sec9descard'>G1 Real Estate is my go-to for property investments. Their professionalism and results-driven approach exceed expectations every time.</p>
                                    </div>
                                    <div className='testimonial-details'>            
                                        <div>
                                            <p className='sec9name'>John Smith</p>
                                            <span className='sec9poz'>Financial Analysit</span>
                                        </div>
                                        <div className='stars'>
                                            <div className='stars-container'>
                                                <span className='stars-count'>4.5</span>
                                                <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.38953 22.2066L6.22781 14.2596L0.0625 8.91445L8.2075 8.20742L11.375 0.712891L14.5425 8.20742L22.6875 8.91445L16.5222 14.2596L18.3605 22.2066L11.375 17.9927L4.38953 22.2066Z" fill="#0A0A0A"/>
                                                </svg>
                                            </div>
                                        </div>              
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='testimonial-slide'>
                            <div class="contentsec9">
                                <div className='testimonial-image-container'>
                                    <div className='agujero'>
                                        <img src={man} className='manroundsec9' />
                                    </div>
                                </div>
                                <div className='testimonial-content'>
                                    <div className='testimonial'>
                                        <p className='sec9descard'>G1 Real Estate is my go-to for property investments. Their professionalism and results-driven approach exceed expectations every time.</p>
                                    </div>
                                    <div className='testimonial-details'>            
                                        <div>
                                            <p className='sec9name'>John Smith</p>
                                            <span className='sec9poz'>Financial Analysit</span>
                                        </div>
                                        <div className='stars'>
                                            <div className='stars-container'>
                                                <span className='stars-count'>4.5</span>
                                                <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.38953 22.2066L6.22781 14.2596L0.0625 8.91445L8.2075 8.20742L11.375 0.712891L14.5425 8.20742L22.6875 8.91445L16.5222 14.2596L18.3605 22.2066L11.375 17.9927L4.38953 22.2066Z" fill="#0A0A0A"/>
                                                </svg>
                                            </div>
                                        </div>              
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='testimonial-slide'>
                            <div class="contentsec9">
                                <div className='testimonial-image-container'>
                                    <div className='agujero'>
                                        <img src={man} className='manroundsec9' />
                                    </div>
                                </div>
                                <div className='testimonial-content'>
                                    <div className='testimonial'>
                                        <p className='sec9descard'>G1 Real Estate is my go-to for property investments. Their professionalism and results-driven approach exceed expectations every time.</p>
                                    </div>
                                    <div className='testimonial-details'>            
                                        <div>
                                            <p className='sec9name'>John Smith</p>
                                            <span className='sec9poz'>Financial Analysit</span>
                                        </div>
                                        <div className='stars'>
                                            <div className='stars-container'>
                                                <span className='stars-count'>4.5</span>
                                                <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.38953 22.2066L6.22781 14.2596L0.0625 8.91445L8.2075 8.20742L11.375 0.712891L14.5425 8.20742L22.6875 8.91445L16.5222 14.2596L18.3605 22.2066L11.375 17.9927L4.38953 22.2066Z" fill="#0A0A0A"/>
                                                </svg>
                                            </div>
                                        </div>              
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
                <div className='btns-container'>
                    <button onClick={nextTestimonial}>
                        <svg width="52" height="25" viewBox="0 0 52 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.11523 12.8253L49.9194 12.8253M2.11523 12.8253L12.9874 1.95312M2.11523 12.8253L12.9874 23.6974" stroke="white" stroke-width="2.2625" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </button>
                    <button onClick={nextTestimonial}>
                        <svg width="51" height="25" viewBox="0 0 51 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M49.2012 12.8253L1.39697 12.8253M49.2012 12.8253L38.329 1.95312M49.2012 12.8253L38.329 23.6974" stroke="white" stroke-width="2.2625" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </button>
                </div>
                </div>
            </div>
        </>
    )
}
export default Section8;