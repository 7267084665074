import React from 'react';

import logo from "../media/logo.png";
import '../styles/News.css'
function Newsletter() {
    return (
        <>
            <div className='section11'>
                <div className='newsletter'>
                    <h4 className='latestUpdates'>Looking for the latest updates?</h4>
                    <div className='newsInputContainer'>
                        <input type='email' className='inputEmail' placeholder='Enter your Email' />
                        <button className='newsBtn'>
                        <svg width="51" height="24" viewBox="0 0 51 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M49.4814 12.0263L0.999607 12.0263M49.4814 12.0263L38.4552 1M49.4814 12.0263L38.4552 23.0526" stroke="white" stroke-width="1.83772" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        </button>
                    </div>
                    <div className='termsContainer'>
                        <span className='submittingTerms'>By submitting, you agree to our Terms & Conditions and Privacy Policy.</span>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Newsletter;