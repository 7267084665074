import React from 'react';
import bg1 from "../media/background1sec5.jpg";
import bg2 from "../media/background2sec5.jpg";
import arrowRight from "../media/arrowRight.png";
import arrowLeft from "../media/arrowLeft.png";
function Section5() {
    const nextOffPlan = () =>{
        let lists = document.querySelectorAll('.off-plan-slide');
        document.querySelector('.off-plan-track').appendChild(lists[0]); 
    }
    const previousOffPlan = () => {
        const track = document.querySelector('.off-plan-track');
        let lists = track.querySelectorAll('.off-plan-slide');
        track.prepend(lists[lists.length - 1]);
    }
    return (
        <>
            <div className='section6'>
                <div className='sec5Content'>
                    <p className='premiumhead'>FEATURED PLANS</p>
                    <h4 className='sec9headmain'>Off-Plan Developments</h4>
                </div>
                <div className='offplan-btn-container'>
                    <button className="btnRight"  onClick={previousOffPlan}>
                        <svg width="73" height="35" viewBox="0 0 73 35" fill="#000" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="1" d="M2 17.7911L71.4324 17.7911M2 17.7911L17.7911 2M2 17.7911L17.7911 33.5821" stroke="#000" stroke-width="2.63185" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>    
                    </button>
                    <button className="w3-button w3-display-right w3-black  btn2 " onClick={nextOffPlan}>
                        <svg width="73" height="36" viewBox="0 0 73 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M71.6484 17.9844L2.21605 17.9844M71.6484 17.9844L55.8573 2.19336M71.6484 17.9844L55.8573 33.7755" stroke="#0A0A0A" stroke-width="2.63185" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </button>
                </div>
                <div className='off-plan-track'>
                        <div className='sec5contentBox off-plan-slide'>
                            <img src={bg1} className='bg1sec5contentbox' />
                            <h4 className='sec5contentboxhead'>Golden Sands</h4>
                            <p className='sec5contentboxtext'>Exclusive desert oasis offering modern design, lush landscaping, and tranquility.</p>
                        </div>
                        <div className='sec5contentBox off-plan-slide'>
                            <img src={bg2} className='bg1sec5contentbox' />
                            <h4 className='sec5contentboxhead'>Crystal Lagoon</h4>
                            <p className='sec5contentboxtext'>Luxe waterfront lifestyle with stunning views and modern amenities.</p>
                        </div>
                        <div className='sec5contentBox off-plan-slide'>
                            <img src={bg2} className='bg1sec5contentbox' />
                            <h4 className='sec5contentboxhead'>Crystal Lagoon</h4>
                            <p className='sec5contentboxtext'>Luxe waterfront lifestyle with stunning views and modern amenities.</p>
                        </div>
                        <div className='sec5contentBox off-plan-slide'>
                            <img src={bg2} className='bg1sec5contentbox' />
                            <h4 className='sec5contentboxhead'>Crystal Lagoon</h4>
                            <p className='sec5contentboxtext'>Luxe waterfront lifestyle with stunning views and modern amenities.</p>
                        </div>
                </div>
                <button className='contactNav sec6btnlast'>Explore More</button>
            </div>
            
        </>
    )
}
export default Section5;