import '../styles/Networks.css'
function Networks({networks}){
    console.log(networks)
    return (
        <>
            <ul className='networks'>
                {networks.map(network =>{
                    return (
                    <li className="networkItem">
                        <a dangerouslySetInnerHTML={{ __html: network.svg }}  href={network.url}>
                    
                        </a>
                    </li>
                    )
                })}
            </ul>
        </>
    )
}

export default Networks