import React, { useState } from 'react';
import '../styles/App.css';
import bg1 from '../media/background1.jpg';
import bg2 from '../media/background2.png';
import bg3 from "../media/G1ESTATE.png";
import icon3Tabs from "../media/icon3Tabspng.png";
import icon1Tabs from "../media/icon1Tabs.png";
import icon2Tabs from "../media/icon2Tabs.png";
import arrowRight from "../media/arrowRight.png";
import whatsapplogo from "../media/whatsapplogo.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'
import '../styles/App.css';
import logo from '../media/logo.png';
import googlefbrating from '../media/googleFBreview.png';
import ModalEl from './Modal';



function Section1() {
    const [openModal, setOpenModal] = useState(false);

    const handleOpenModal = () => {
        setOpenModal(true);
    };
    return (
    <>  
        <div className='main'>
            <header>
                <div className='header-content'>
                    <div className='logo-container'>
                        <img src={logo} alt="Logo" className='logo'/>
                    </div>
                    <nav>
                        <ul>
                            <li className='nav-item'><a href="#" className="nav-menu-item">Off Plan</a></li>
                            <li className='nav-item'><a href="#">Services</a></li>
                            <li className='nav-item'><a href="#">About Us</a></li>
                            <li className='nav-item'><a href="#">Blog</a></li>
                            <li className='nav-item'><FontAwesomeIcon icon={faEnvelope} className='envelopeIcon'/></li>
                            <li className='nav-item'><FontAwesomeIcon icon={faPhone} className='phoneIcon'/></li>
                            <li className='nav-item'><button className='contactNav'>Contact Us</button></li>
                            <li className='nav-item'><a className='languageNav'>ENG </a></li>
                        </ul>

                        
                        
                        
                        
                        
                        
                                    
                        
                        
                    </nav>
                </div>
            </header>
           {/*  <div className='firstBg-container'>
                <img src={bg1} className='firstBg' />   
            </div> */}
            <img src={whatsapplogo} className='whatsappLogo' width="110px" height="100px"/>
            <div className="section1">
                <div className='title-reviews'>
                    <img src={googlefbrating} alt="google/fb rating" />
                    <h1 className='mainTitle'>G1 REAL ESTATE</h1>
                    <p className='descriptionMain'>Discover Unrivaled Luxury in Dubai's Premier Real Estate Market</p>
                </div>
                <div className='stats'>
                    <div className='stat1'>
                        <h4 className='mainStat1'>38908</h4>
                        <p className='Stat1Description'>ORN</p>
                    </div>
                    <div className='stat2'>
                        <h4 className='mainStat2'>1259624</h4>
                        <p className='Stat2Description'>Company License Number</p>
                    </div>
                </div>
            </div>
            <div className='secondBg-container'>
                <img src={bg2} className='secondBg' />
            </div>
            <div className='thirdBg-container'>
                <img src={bg3} className='thirdBg' />
            </div>
        </div>
        <div className='section2'>
            <div className='tabSection'>
                <div className="tab">
                    <button className="tablinks">Buy</button>
                    <button className="tablinks">Rent</button>
                    <button className="tablinks">List</button>
                </div>
                <div className='tabContent'>
                        <div className="tabcontent">
                            <div className='part1tab'>
                                <h3>Location</h3>
                                <div className='tab-input'>
                                    <input type="text" name="location" className='addressInput inputab1' placeholder='Select your area'/>
                                    <img src={icon1Tabs} className='icon1Tabs' />
                                </div>
                            </div>
                            <span className='verticalLine'></span>  
                            <div className='part2tab'>
                                <h3>Property Type</h3>
                                <div className='tab-input'>
                                    <input type="text" name="location" className='addressInput inputab2' placeholder='Choose property type'/>
                                    <img src={icon2Tabs} className='icon2Tabs' />
                                </div>
                            </div>
                            <span className='verticalLine'></span> 
                            <div className='part3tab'>
                                <h3>Budget</h3>
                                <div className='tab-input'>
                                    <input type="text" name="location" className='addressInput inputab3' placeholder='Select a budget'/>
                                    <img src={icon3Tabs} className='icon3Tabs' />
                                </div>
                            </div>
                            <span className='verticalLine'></span> 
                            <div className='part3tab'>
                                <button className='contactNav2' onClick={handleOpenModal}>Search</button>
                            </div>
                        </div>
                        <ModalEl openModal={openModal} setOpenModal={setOpenModal}/>
                </div>
            </div>
            <div className='section2part2'>
                <div className='properties-title-container'>
                    <div className='featuredProperties'>
                        <div>
                            <hr className='hr1'/>
                            <p className='premiumhead'>PREMIUM</p>
                            <h3 className='featuredhead'>Our Featured Properties</h3>
                        </div>
                        <div className='rightsideFeatured'>
                            <p className='featuredcta'>Explore All</p>
                            <svg width="51" height="25" viewBox="0 0 51 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M49.4141 12.746L0.932225 12.746M49.4141 12.746L38.3878 1.71973M49.4141 12.746L38.3878 23.7723" stroke="#222222" stroke-width="1.83772" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            
        </>
    )
}
export default Section1;