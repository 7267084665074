import React, { useState, useEffect } from 'react';
import dubaiarea1 from "../media/dubaiarea1.jpg";
import dubaiarea2 from "../media/dubaiarea2.jpg";
import dubaiarea3 from "../media/dubaiarea3.jpg";
import arrowRight from "../media/arrowRight.png";
import arrowLeft from "../media/arrowLeft.png";
function Section3() {
  const nextAreas = () =>{
    let lists = document.querySelectorAll('.areas-slide');
    document.querySelector('.mySlides').appendChild(lists[0]); 
  }
  
  const previousAreas = () => {
      const track = document.querySelector('.mySlides');
      let lists = track.querySelectorAll('.areas-slide');
      track.prepend(lists[lists.length - 1]);
  }

    return (
        <>
           <div className='section4'>
        <p className='descriptionsec4'>POPULAR AREAS</p>
        <h4 className='mainHeading4 sec9headmain'>Explore Dubai's Areas</h4>
        <div className="w3-container">
          <div className="areas-carousel">
            <div className="mySlides">
              <div className="areas-slide">
                  <div className='contentdubaiarea db1'>
                    <img src={dubaiarea1} className='imageDubai1' />
                    <h5 className='showcontentHead'>Palm Jumeirah</h5>
                    <p className='showcontentDescription'>Immerse yourself in luxury island living with opulent residences and pristine beaches.</p>
                  </div>
                  <div className='contentdubaiarea db2'>
                    <img src={dubaiarea2} className='imageDubai1' />
                    <h5 className='showcontentHead'>Palm Jumeirah</h5>
                    <p className='showcontentDescription'>Immerse yourself in luxury island living with opulent residences and pristine beaches.</p>
                  </div>
                  <div className='contentdubaiarea db3'>
                    <img src={dubaiarea3} className='imageDubai1' />
                    <h5 className='showcontentHead'>Palm Jumeirah</h5>
                    <p className='showcontentDescription'>Immerse yourself in luxury island living with opulent residences and pristine beaches.</p>
                  </div>
              </div>
              <div className="areas-slide">
                  <div className='contentdubaiarea db1'>
                    <img src={dubaiarea1} className='imageDubai1' />
                    <h5 className='showcontentHead'>Palm Jumeirah</h5>
                    <p className='showcontentDescription'>Immerse yourself in luxury island living with opulent residences and pristine beaches.</p>
                  </div>
                  <div className='contentdubaiarea db2'>
                    <img src={dubaiarea2} className='imageDubai1' />
                    <h5 className='showcontentHead'>Palm Jumeirah</h5>
                    <p className='showcontentDescription'>Immerse yourself in luxury island living with opulent residences and pristine beaches.</p>
                  </div>
                  <div className='contentdubaiarea db3'>
                    <img src={dubaiarea3} className='imageDubai1' />
                    <h5 className='showcontentHead'>Palm Jumeirah</h5>
                    <p className='showcontentDescription'>Immerse yourself in luxury island living with opulent residences and pristine beaches.</p>
                  </div>
              </div>
              <div className="areas-slide">
                  <div className='contentdubaiarea db1'>
                    <img src={dubaiarea1} className='imageDubai1' />
                    <h5 className='showcontentHead'>Palm Jumeirah</h5>
                    <p className='showcontentDescription'>Immerse yourself in luxury island living with opulent residences and pristine beaches.</p>
                  </div>
                  <div className='contentdubaiarea db2'>
                    <img src={dubaiarea2} className='imageDubai1' />
                    <h5 className='showcontentHead'>Palm Jumeirah</h5>
                    <p className='showcontentDescription'>Immerse yourself in luxury island living with opulent residences and pristine beaches.</p>
                  </div>
                  <div className='contentdubaiarea db3'>
                    <img src={dubaiarea3} className='imageDubai1' />
                    <h5 className='showcontentHead'>Palm Jumeirah</h5>
                    <p className='showcontentDescription'>Immerse yourself in luxury island living with opulent residences and pristine beaches.</p>
                  </div>
              </div>
            </div>
          </div>
          <div className='areas-btn-container'>
              <button onClick={previousAreas} className="btnRight" >
                  <svg width="73" height="35" viewBox="0 0 73 35" fill="#000" xmlns="http://www.w3.org/2000/svg">
                  <path opacity="1" d="M2 17.7911L71.4324 17.7911M2 17.7911L17.7911 2M2 17.7911L17.7911 33.5821" stroke="#000" stroke-width="2.63185" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>    
              </button>
              <button onClick={nextAreas} className="w3-button w3-display-right w3-black btn2">
                <svg width="73" height="36" viewBox="0 0 73 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M71.6484 17.9844L2.21605 17.9844M71.6484 17.9844L55.8573 2.19336M71.6484 17.9844L55.8573 33.7755" stroke="#0A0A0A" stroke-width="2.63185" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

              </button>
          </div>
        </div>
      </div>
    </>
     
    )
}
export default Section3;