import '../styles/Footer.css'
import logo from '../media/logo.png';


function Footer(){
    return (
        <div id="footer" class="footer">
            <div class="container-footer">
                <div class="outer-grid">
                    <div class="main-grid">
                        <div class="contact-footer-main">
                            <div class="logo-footer">                    
                                <img loading="lazy" width="35px" height="35ppx" src={logo} alt="G1 Real Estate logo"/>
                            </div>
                            <div class="part-container">
                                <a href='tel:97144218844' class="contact1">+971 4 421 8844</a>
                                <a href="#" class="contact1">info@g1realestate.ae</a>
                                <div class="note contact1">© 2024 G1 Real Estate. All Rights Reserved.</div>
                            </div>
                        </div>
                        <div>
                            <div class="part-container">
                                <h6 class="heading-footer">Sitemap</h6>
                                <a href="/" class="link-3">Home</a>
                                <a href="/" class="link-3">Properties</a>
                                <a href="/" class="link-3">Off-plan</a>
                                <a href="/" class="link-3">Developers</a>
                                <a href="/" class="link-3">List your property</a>
                                <a href="/" class="link-3">About us</a>
                                <a href="contact.html" class="link-3">Contact</a>
                            </div>
                        </div>
                        <div class="contact-footer-main">
                            <div class="part-container">
                                <div class="toggle-container">
                                    <div class="toggle">
                                        <h6 class="heading-footer">Find your property</h6><img src="https://uploads-ssl.webflow.com/641f4e607e674a3cedce0588/64282b7d0cb3c8b682832c30_Vector%20113.svg" loading="lazy" alt=""/>
                                    </div>

                                    <ul class="optionContainer" id="encontrar">
                                    </ul>
                                </div>

                            </div>
                        </div>
                        <div class="seguinos">
                            <div class="redes-container">
                                <h6 class="heading-footer">Follow us</h6>
                                <div class="redes">
                                    <a aria-label="Our instagram" href="">
                                    <img src="https://uploads-ssl.webflow.com/641f4e607e674a3cedce0588/64282b7d0cb3c87627832c32_instagram%20(1)%201.svg" loading="lazy" alt=""/>
                                    </a>
                                    <a aria-label="Our linkedin" href="">
                                        <img src="https://uploads-ssl.webflow.com/641f4e607e674a3cedce0588/64282b7d0cb3c8b0d8832c33_linkedin%201.svg" loading="lazy" alt=""/>
                                    </a>
                                    <a aria-label="Our facebook" href="">
                                        <img src="https://uploads-ssl.webflow.com/641f4e607e674a3cedce0588/64282b7d0cb3c8f790832c31_facebook%20(1)%201.svg" loading="lazy" alt=""/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="note-container">
                        <div class="note"></div>
                        <div class="note">Built with <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.8515 21.5964L10.2112 20.1031C4.38529 14.8202 0.539043 11.3246 0.539043 7.05981C0.539043 3.56425 3.27667 0.837934 6.76092 0.837934C8.72929 0.837934 10.6185 1.75425 11.8515 3.19093C13.0846 1.75425 14.9738 0.837934 16.9422 0.837934C20.4264 0.837934 23.164 3.56425 23.164 7.05981C23.164 11.3246 19.3178 14.8202 13.4919 20.1031L11.8515 21.5964Z" fill="#407FF0"/></svg> by <a href="https://propphy.com" class="link-propphy">Propphy</a></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer